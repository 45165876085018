nav {
  transition: 0.5s ease;
}

.nav-buttons {
  font-family: 'NeueMontreal';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vh;
  line-height: 17px;
  /* identical to box height */
  padding: 2vh 2vw 0px 0px;

  transition: 0.5s ease;

  color: #000000;
}

.nav-link {
  color: #000000;
  margin-left: 6vw;
  transition: 0.5s ease;
}

.nav-link:hover {
  letter-spacing: 0.2em;
  transition: 0.5s ease;
}

.nav-link:focus {
  letter-spacing: 0.2em;
  transition: 0.5s ease;
}

.nav-link:active {
  letter-spacing: 0.2em;
  transition: 0.5s ease;
}

.nav-scrolled {
  padding: 0 !important;
  background-color: var(--white);
  transition: 1s ease;
  border-bottom: 2px solid var(--black);
  margin-left: 2vw;
  margin-right: 2vw;
  
}

.nav-scrolled-btn {
  padding: 0;
  transition: 1s ease;
}

.nav-logo {
  opacity: 0;
  transition: 0.2s ease;
  
}
.nav-scrolled-logo {
  opacity: 100%;
  transition: 1s ease;
  transition-delay: 2s;
}

.nav-buttons-m {
  font-family: 'NeueMontreal';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  padding: 0;
  margin-right: 20px;
  margin-top: 5px;
  transition: 1s ease;
  
  
  /* identical to box height */

  color: #000000;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: none;
    outline: none;
    padding-top: 12px;
}


.nav-overlay {
  position: fixed;
  height: 100vh;
  background: var(--white);
  bottom: 100%;
  width: 100%;
  z-index: 4;
  transition: 0.5s ease;
}

.nav-o-container {
  padding: 25px;
  margin-top: 20vh;
  border-bottom: 2px solid var(--black);
  margin-left: 12px;
  margin-right: 12px;
  height: 50%;
}

.nav-o-list {
  list-style-type: none;
  margin: 2px;
  padding: 0;
}
.nav-o-listitem {
  margin-bottom: 22px;
  text-decoration: none;
  font-family: 'NeueMontreal';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 50px;
  color: var(--black);
}
.nav-o-listitem:hover {
  color: black;
}

.version {
  color: var(--lightgray);
  font-size: 1.4vh;
}

.active {
  display: none;
}

.noscroll {
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .nav-scrolled {
    background-color: var(--white);
    transition: 1s ease;
    border-bottom: 2px solid var(--black);
    margin-left: 12px;
    margin-right: 12px;
  }
  .nav-scrolled > div > .nav-buttons-m {
    margin-top: -11px;
    transition: 1s ease;
    margin-right: 0;
  }
}

@media screen and (min-width: 993px) {
  .nav-overlay {
    display: none;
  }
}

@media screen and (max-width: 1200px) and (max-height: 900px) and (min-width: 577px) {
  .nav-buttons {
    font-size: 14px;
  }
}
